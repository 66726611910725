import moment from 'moment';

export class ComplainList {
  constructor({
    id,
    index,
    transactionId,
    customerPhone,
    customerName,
    transactionAmount,
    createdByName,
    createdAt,
    currentStatus,
    storeId,
    storeName,
    transactionDate,
    customerRemarks,
    smMobile,
    approverRemarks,
    images,
    complaintNumber,
    updatedAt,
    transactionType,
    inProgressRemarks
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.customerName = customerName || '';
    this.transactionAmount = transactionAmount || '';
    this.transactionType = transactionType || '';
    this.customerPhone = customerPhone || '';
    this.smMobile = smMobile || '';
    this.transactionId = transactionId || '';
    this.complaintNumber = complaintNumber || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;
    this.transactionDate = transactionDate ? transactionDate : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY') : null;
    this.currentStatus = currentStatus || '';
    this.storeId = storeId || '';
    this.storeName = storeName || '';
    this.createdByName = createdByName || '';
    this.customerRemarks = customerRemarks || '';
    this.approverRemarks = approverRemarks || '';
    this.inProgressRemarks = inProgressRemarks || '';
    this.images = images || [];
  }
}

export class DeletedComplainList {
  constructor({
    id,
    index,
    transactionId,
    customerPhone,
    customerName,
    transactionAmount,
    createdByName,
    createdAt,
    currentStatus,
    storeId,
    storeName,
    transactionDate,
    customerRemarks,
    smMobile,
    approverRemarks,
    images,
    complaintNumber,
    updatedAt,
    archivedByName,
    transactionType
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.customerName = customerName || '';
    this.transactionAmount = transactionAmount || '';
    this.transactionType = transactionType || '';
    this.customerPhone = customerPhone || '';
    this.smMobile = smMobile || '';
    this.transactionId = transactionId || '';
    this.complaintNumber = complaintNumber || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;
    this.transactionDate = transactionDate ? moment(transactionDate).format('YYYY-MM-DD') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY') : null;
    this.currentStatus = currentStatus || '';
    this.storeId = storeId || '';
    this.storeName = storeName || '';
    this.createdByName = createdByName || '';
    this.customerRemarks = customerRemarks || '';
    this.approverRemarks = approverRemarks || '';
    this.archivedByName = archivedByName || '';
    this.images = images || [];
  }
}
